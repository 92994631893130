import { SetStateAction, useState } from "react";
import "./App.css";
import {
  Button,
  Input,
  Spin,
  Upload,
  Image,
  UploadProps,
  Radio,
  RadioChangeEvent,
  Modal,
  Checkbox,
  CheckboxProps,
} from "antd";
import { mediaService } from "./api";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { RcFile, UploadFile } from "antd/es/upload";

const App = () => {
  const [text, setText] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [audioSrc, setAudioSrc] = useState("");
  const [result, setResult] = useState("");
  const [uploading, setUploading] = useState(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResultOpen, setModalResultOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [value, setValue] = useState("1");
  const [valueSex, setValueSex] = useState("m");
  const [valueType, setValueType] = useState("video");
  const [moveHead, setMoveHead] = useState(false);
  const [moveHends, setMoveHends] = useState(false);
  const [moveBody, setMoveBody] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handlePreviewImages = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChangeImages: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    newFileList.map((it) => (it.status = "done"));
    setFileList(newFileList);
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </button>
  );

  const onChangeSex = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValueSex(e.target.value);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalResultOpen(false);
  };

  const handleOk = () => {
    setModalOpen(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onChangeType = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValueType(e.target.value);
  };

  const onChangeHead: CheckboxProps["onChange"] = (e) => {
    setMoveHead(e.target.checked);
  };

  const onChangeHends: CheckboxProps["onChange"] = (e) => {
    setMoveHends(e.target.checked);
  };

  const onChangeBody: CheckboxProps["onChange"] = (e) => {
    setMoveBody(e.target.checked);
  };

  const handleSubmit = () => {
    setModalResultOpen(true);
    const formData = new FormData();

    formData.append("request_model_name", "large-v2");
    formData.append("url", text);

    setUploading(true);
    mediaService
      .postUrl(formData)
      .then((resp) => {
        setResult(resp.text);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  };

  const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  async function handleUpload(file: Blob) {
    const formData = new FormData();
    formData.append("request_model_name", "large-v2");
    formData.append("video", file);

    setUploading(true);
    mediaService
      .postVideo(formData)
      .then((resp) => {
        setResult(resp.text);
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  }

  const changeText = (value: SetStateAction<string>) => {
    setText(value);
  };

  const uploadImage = (value: any) => {
    console.log(value);
  };

  const props: any = {
    //action: (file: RcFile) => uploadImage(file),
    listType: "picture",
    /*previewFile(file: string | Blob) {
      console.log("Your upload file:", file);
    },*/
  };

  const handleChange = ({ file }: any) => {
    setResult("");
    file.status = "done";
    console.log("RRRR", file);
    let url = URL.createObjectURL(file?.originFileObj);
    if (file.type.includes("video")) setVideoSrc(url);
    if (file.type.includes("audio")) setAudioSrc(url);
  };

  const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  let audio = new Audio("/female.aac");

  const start = () => {
    audio.play();
  };

  const downloadVideo = () => {
    const a = document.createElement("a");
    a.href = "VID-20211012-WA0019.mp4";
    a.download = `Результат.mp4`;
    a.click();
  };

  return (
    <div className="flex-container">
      <div>
        <p>AI - видео</p>
        {uploading ? (
          <div>
            <div>
              <p>Видео обрабатывается...</p>
              <div>
                <Spin indicator={antIcon} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <Radio.Group
                onChange={onChangeType}
                value={valueType}
                style={{ width: 500 }}
              >
                <Radio.Button value={"video"} style={{ width: 200 }}>
                  Видео
                </Radio.Button>
                <Radio.Button value={"image"} style={{ width: 200 }}>
                  Серия фотографий лиц
                </Radio.Button>
              </Radio.Group>
              {valueType == "video" && (
                <>
                  <Upload
                    onChange={handleChange}
                    onPreview={handlePreview}
                    maxCount={1}
                  >
                    <Button icon={<DownloadOutlined />}>Загрузите видео</Button>
                  </Upload>
                  <div>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                    {videoSrc && (
                      <video
                        id="video"
                        width="600"
                        height="315"
                        src={videoSrc}
                        controls
                      ></video>
                    )}
                  </div>
                </>
              )}
              {valueType == "image" && (
                <>
                  {/*
                    <Upload
                      {...props}
                      onChange={handleChange}
                      onPreview={handlePreview}
                      maxCount={50}
                      multiple
                    >
                      <Button icon={<DownloadOutlined />}>
                        Загрузите фотографии
                      </Button>
                    </Upload>*/}

                  <Upload
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreviewImages}
                    onChange={handleChangeImages}
                    multiple
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                </>
              )}
              <Radio.Group
                onChange={onChange}
                value={value}
                style={{ width: 500 }}
              >
                <Radio.Button value={"1"} style={{ width: 200 }}>
                  Ваш голос
                </Radio.Button>
                <Radio.Button value={"2"} style={{ width: 200 }}>
                  Голос AI
                </Radio.Button>
              </Radio.Group>
              {value == "1" && (
                <>
                  <Upload onChange={handleChange} onPreview={handlePreview}>
                    <Button icon={<DownloadOutlined />}>
                      Загрузите ваш голос
                    </Button>
                  </Upload>
                  <div>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                    {audioSrc && (
                      <video
                        width="500"
                        height="40"
                        src={audioSrc}
                        controls
                      ></video>
                    )}
                  </div>
                </>
              )}
              {value == "2" && (
                <Radio.Group
                  onChange={onChangeSex}
                  value={valueSex}
                  style={{ width: 500 }}
                >
                  <Radio value={"m"} onClick={start}>
                    <PlayCircleOutlined /> Мужской
                  </Radio>
                  <Radio value={"f"} onClick={start}>
                    {" "}
                    <PlayCircleOutlined /> Женский
                  </Radio>
                </Radio.Group>
              )}
              <span>Введите текст:</span>
              <TextArea
                rows={4}
                value={text}
                onChange={(evt) => changeText(evt.target.value)}
                style={{ width: 550 }}
                placeholder="Введите текст"
              ></TextArea>
              <Button onClick={() => setModalOpen(true)}>
                <SettingOutlined />
              </Button>
              <Button onClick={handleSubmit} type="primary" disabled={!text}>
                Сгенерировать
              </Button>
            </div>
          </>
        )}
      </div>
      {
        <div>
          {result && (
            <div>
              <div className="flex-between">
                <label>Транскрипт видео:</label>
                <Button type="primary">
                  <a
                    href={`data:text/plain;charset=utf-8,%EF%BB%BF ${encodeURIComponent(
                      result
                    )}`}
                    download="text.txt"
                  >
                    Скачать текст
                  </a>
                </Button>
              </div>
              <TextArea
                rows={15}
                value={result}
                style={{ width: 600 }}
                onChange={(evt) => setResult(evt.target.value)}
              />
            </div>
          )}
        </div>
      }
      <Modal
        title={`Настройки`}
        open={modalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelText="Отменить"
        okText="Сохранить"
        //footer={null}
        maskClosable={false}
      >
        <p>
          <Checkbox onChange={onChangeHead} checked={moveHead}>
            Движение головы
          </Checkbox>
        </p>
        <p>
          <Upload {...props} onChange={handleChange} onPreview={handlePreview} maxCount={1}>
            <Button icon={<DownloadOutlined />}>Загрузить фон</Button>
          </Upload>
        </p>
        <p>
          <Checkbox onChange={onChangeBody} checked={moveBody}>
            Движение телом
          </Checkbox>
        </p>
        <p>
          <Checkbox onChange={onChangeHends} checked={moveHends}>
            Жестикуляция руками
          </Checkbox>
        </p>
      </Modal>
      <Modal
        title={`AI - видео`}
        open={modalResultOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelText="Назад"
        okText="Скачать"
        footer={null}
        maskClosable={false}
      >
        <video
          id="video"
          width="450"
          height="315"
          src={"VID-20211012-WA0019.mp4"}
          controls
        ></video>
        <Button onClick={() => handleCancel()}>
          <ArrowLeftOutlined /> Назад
        </Button>
        <Button type="primary" onClick={() => downloadVideo()}>
          <UploadOutlined /> Скачать
        </Button>
      </Modal>
    </div>
  );
};

export default App;
