import { Button, Input } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const LoginComponent = () => {
  const navigate = useNavigate();
  const [passwordRef, setPasswordRef] = useState<any>("");
  const handleSubmit = () => {
    localStorage.setItem("pass", passwordRef);
    navigate("/main");
  };
  let currentPass = localStorage.getItem("pass");

  return (
    <div className="flex-container">
      <form onSubmit={handleSubmit}>
        <h3 className="form-label">Введите пароль для входа в систему</h3>
        <div className="form-group">
          <Input.Password
            className="login__input form__input"
            placeholder="Пароль"
            id="password"
            onChange={(evt) => setPasswordRef(evt.target.value)}
          />
          <p className="red">
            {currentPass != "1235" && currentPass != "" && "Неверный пароль"}
          </p>
        </div>
        <Button onClick={handleSubmit} type="primary">
          Войти
        </Button>
      </form>
    </div>
  );
};
