import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {PrivateRoute} from './PrivateRout';
import { LoginComponent } from './Login';
import { Button, Result } from 'antd';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Login = () => {
  return <><LoginComponent/></>;
}
const NotFound = () => {
  return   <Result
  status="404"
  title="404"
  subTitle="Такой страницы не существует"
  extra={<a href='/'><Button type="primary">На главную</Button></a>}
/>;
}

root.render(
  <Router>
  <div>
    <Routes>
      <Route path="/" element={<Login />}/>
      <Route element={<PrivateRoute/>}>
        <Route path="/main" element={<App/>} />
      </Route> 
      <Route path="*" element={<NotFound />} />
     </Routes>
  </div>
</Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
